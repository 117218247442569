@keyframes bell-ring {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  70%,
  100% {
    transform: rotate(0);
  }
}

.bell-animation {
  animation: bell-ring 1s ease-in-out;
  transform-origin: top center;
}
