.layout {
  display: flex;
}

.main-content {
  margin-left: 200px; /* 侧边栏宽度 */
  padding: 20px;
  width: 100%;
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0; /* 移动设备上侧边栏收起 */
  }
}
