@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap");

body {
  background-color: #dde1ea;
  font-family: "Noto Sans JP", "Noto Sans SC", "Noto Sans TC", "Noto Sans",
    sans-serif;
}
.nav-tabs {
  margin-top: 10px;
  border-bottom: 2px solid #ddd;
}

.nav-tabs .nav-link {
  margin-right: 2px;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  color: #767676; /* inactive 顏色 */
  padding: 5px 10px; /* 調整padding, 收窄上下間距 */
  font-size: 0.9rem; /* 調整字體大小 */
}

.nav-tabs .nav-link.active {
  color: #323232; /* active 顏色 */
  background-color: #fff;
  border-color: #ddd #ddd #fff;
  font-weight: bold;
  padding: 5px 10px; /* 與inactive的書籤一致 */
  font-size: 0.9rem; /* 與inactive的書籤一致 */
}
/* navbar */

.nav-item {
  font-weight: bold;
}

.navbar {
  background-color: #f8f9fa !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
}

.hyper-link-in-page {
  display: flex;
  align-items: center;
  justify-content: center; /* 水平居中对齐 */
  gap: 1rem; /* 元素之间的间距 */
  margin-top: 5px; /* 与上方内容保持距离 */
  width: 100%; /* 使 div 占满整个画面 */
  box-sizing: border-box; /* 包括 padding 在内的宽度计算 */
  padding: 0.3rem 0rem;
}

.hyper-link-in-page span {
  display: inline-block;
  padding: 0.5rem 1rem;
}

.hyper-link-in-page a {
  font-size: 1rem;
  text-decoration: none;
  color: gray;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s; /* 添加过渡效果 */
}

.hyper-link-in-page a:hover {
  color: #484848; /* 悬停时字体颜色加深 */
}

/* 以上是確定有用處的 */
