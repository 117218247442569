.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  backdrop-filter: blur(5px);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
  will-change: transform;
}

.loading-spinner {
  position: absolute;
  width: 200px;
  height: 200px;
  transform-origin: center center;
  will-change: transform;
  transform: translateZ(0);
}

.loading-spinner::before,
.loading-spinner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 4px solid transparent;
  transform: translateZ(0);
}

.loading-spinner::before {
  border-top-color: #ff0000;
  animation: spin-stretch 1.5s linear infinite;
}

.loading-spinner::after {
  border-top-color: rgba(255, 0, 0, 0.3);
  animation: spin-stretch 1.5s linear infinite reverse;
  opacity: 0.6;
}

.logo-center {
  position: relative;
  width: 150px;
  height: auto;
  z-index: 1;
  transform: translateZ(0);
  will-change: transform;
}

@keyframes spin-stretch {
  0% {
    transform: rotate(0deg);
    border-width: 4px;
  }
  50% {
    transform: rotate(180deg);
    border-width: 2px;
  }
  100% {
    transform: rotate(360deg);
    border-width: 4px;
  }
}
